











  import { Vue, Component } from 'nuxt-property-decorator'

  @Component
  export default class LoginLogoutComponent extends Vue {
    onLogin() {
      this.$router.push(`${this.localePath('login')}`)
    }

    onLogout() {
      this.$auth.logout()
      this.$router.push({
        path: this.$tenantConfig.isCatalogOpened ? `${this.localePath('/')}` : `${this.localePath('login')}`,
      })
    }
  }
